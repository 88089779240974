/* SIDEBAR  *******************************************************************/
#content-wrapper {

  .toggle-sidebar {
    position: fixed;
    z-index: 10;
    top: 12px;
    left: 16px;

    @media (max-width: 599px) {
      top: 8px;
    }
  }

  .sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -250px;
    transition: left 0.3s;
    display: flex;
    flex-direction: column;
    z-index: 10;

    .sidebar-header {
      height: 66px;

      @media (max-width: 599px) {
        height: 48px;
      }
    }

    .sidebar-body {
      flex-grow: 1;
      overflow: auto;

      height: calc(100% - 97px);

      @media (max-width: 599px) {
        height: calc(100% - 112px);
      }

      .actions-container {
        padding: 4px;

        button {
          width: 100%;
        }
      }
    }

    .sidebar-footer {
      height: 64px;
      text-align: right;

      @media (max-width: 599px) {
        height: 48px;
      }
    }

    .menu-item {
      padding: 10px;
    }
  }

  .main-content {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: 0;
    transition: margin-left 0.3s;
  }

  #full-page-toolbar {
    padding-left: 36px;
  }

  &.open-sidebar {

    .toggle-sidebar {
      left: 200px;
    }

    .sidebar {
      left: 0;

      @media (max-width: 599px) {
        position: fixed;
        top: 0;
      }
    }

    .main-content {
      @media (min-width: 600px) {
        margin-left: 250px;
        width: calc(100% - 250px);
      }

      @media (max-width: 599px) {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          content: ' ';
          height: 100%;
          width: 100%;
        }
      }
    }

    #full-page-toolbar {
      padding-left: 0;
    }
  }
}

/* ****************************************************************************/
#logo {
  margin: 8px 64px 8px 16px;
  //max-width: 180px;
  height: 46px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    display: inline-block;
    max-height: 100%;
    max-width: 75%;
    width: 125px;
    height: 40px;
    background-color: #ccc;
    border: 8px;
  }
}
