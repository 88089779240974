.react-resizable-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 4px;
  right: 4px;
  cursor: se-resize;
  background-color: transparent;
  border-right: 2px solid rgba(255, 255, 255, 0.8); /* Branco transparente para contraste */
  border-bottom: 2px solid rgba(255, 255, 255, 0.8); /* Branco transparente para contraste */
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22white%22%3E%3Cpath d=%22M0 0h24v24H0z%22 fill=%22none%22/%3E%3Cpath d=%22M15 11v2h4v-2h-4zM11 11v2h2v-2h-2zM7 11v2h2v-2H7zm8-4v2h2V7h-2zm-4 0v2h2V7h-2zm-4 0v2h2V7H7zm8 8v2h2v-2h-2zm-4 0v2h2v-2h-2zm-4 0v2h2v-2H7z%22/%3E%3C/svg%3E'); /* ícone de redimensionamento */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.react-resizable-handle:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Efeito de destaque */
  border-color: rgba(255, 255, 255, 1);
}

.minimized .react-resizable-handle {
  display: none;
}

@keyframes skeleton-wave {
  0% {
    background-position: -217px 0;
  }
  100% {
    background-position: calc(217px + 100%) 0;
  }
}

.skeleton-wave {
  background: linear-gradient(90deg, #111 25%, #555 50%, #111 75%); /* Cores mais escuras */
  background-size: 217px 100%;
  animation: skeleton-wave 3s infinite; /* Animação mais lenta */
}
